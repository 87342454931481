<template>
  <div class="content">
    <app-cells position="between">
      <app-cells :indent="false">
        <h1 class="title title--theme">Договор (ID: {{ form.id }})</h1>
        <div class="status" v-if="statusObj">
          <div class="status__item" :class="`status__item--${statusObj.code}`">{{ statusObj.name }}</div>
        </div>
      </app-cells>
      <app-button
        v-if="userIsHead && (form.status === 1 || form.status === 3)"
        theme="error"
        size="small"
        @click="onShowModal"
      >
        Удалить
      </app-button>
    </app-cells>
    <div v-if="!userIsAdmin && form.status === 3 && form.comment_reject" class="notice notice--mb notice--red">
      <div class="notice__title">
        <img src="@/assets/img/info-icon-red.svg" alt="Info icon">
        <span>Комментарий отказа</span>
      </div>
      <p class="notice__text">
        {{ form.comment_reject }}
      </p>
    </div>
    <app-cells v-if="userIsAdmin || (userIsHead && form.status === 4)" position="start">
      <template v-if="userIsAdmin">
        <app-button
          size="link"
          :class="{'btn--loading': download_app.word}"
          @click="onDownloadApplication(false, 'docx', 'word', false)"
        >
          <img src="@/assets/img/file/import-icon.svg" alt="Скачать">
          <span>Скачать Word</span>
        </app-button>
        <app-button
          size="link"
          :class="{'btn--loading': download_app.word_seal}"
          @click="onDownloadApplication(false, 'docx', 'word_seal', true)"
        >
          <img src="@/assets/img/file/import-icon.svg" alt="Скачать">
          <span>Скачать Word с печатью</span>
        </app-button>
      </template>
      <template v-if="userIsAdmin || (userIsHead && form.status === 4)">
        <app-button
          size="link"
          :class="{'btn--loading': download_app.pdf}"
          @click="onDownloadApplication(true, 'pdf', 'pdf', false)"
        >
          <img src="@/assets/img/file/import-icon.svg" alt="Скачать">
          <span>Скачать PDF</span>
        </app-button>
        <app-button
          size="link"
          :class="{'btn--loading': download_app.pdf_seal}"
          @click="onDownloadApplication(true, 'pdf', 'pdf_seal', true)"
        >
          <img src="@/assets/img/file/import-icon.svg" alt="Скачать">
          <span>Скачать PDF с печатью</span>
        </app-button>
      </template>
    </app-cells>
    <form @submit.prevent="onCheckForm">
      <app-grid class="grid--indent">
        <template #item-1>
          <app-form-group label="Номер договора" label-for="number">
            <template #additional>
              <app-tooltip>
                <template #icon>
                  <icon-tooltip />
                </template>
                <template #content>
                  <span>Заполняйте только если вашей бухгалтерии принципиален свой номер договора</span>
                </template>
              </app-tooltip>
            </template>
            <app-input
              v-model="form.number"
              id="number"
              placeholder="Введите значение"
              :readonly="readonlyCondition"
            />
          </app-form-group>
          <app-form-group label="Дата договора" label-for="date">
            <app-input
              v-model="form.date"
              v-mask="'99.99.9999'"
              :error="$v.form.date.$error"
              placeholder="дд.мм.гггг"
              autocomplete="off"
              id="date"
              @paste.native.prevent
              :readonly="readonlyCondition"
            />
            <template #error>
              <div v-if="$v.form.date.$dirty && !$v.form.date.underscorePresent">Заполните поле полностью</div>
            </template>
          </app-form-group>
          <app-form-group v-if="form.org_name && form.org_legal_adress" label="Полное название организации в соответствии с учредительными документами в именительном падеже" required>
            <v-select
              v-model="form.org_name"
              :options="org_options"
              :clearable="false"
              :filterable="false"
              @input="onSetOrgName"
              @search="onOrgSearch"
              :getOptionLabel="label => label.value || label.org_name"
              placeholder="Введите значение"
              class="select "
              :class="{ 'select--error': $v.form.org_name.$error }"
              :readonly="readonlyCondition"
            >
              <template #open-indicator>
                <svg class="select__open-indicator" xmlns="http://www.w3.org/2000/svg" width="23" height="23"
                     fill="none">
                  <path clip-rule="evenodd" d="M10.06 17.25a7.19 7.19 0 100-14.38 7.19 7.19 0 000 14.38z"
                        stroke="#C7CFDD" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                  <path d="M20.12 20.12l-4.98-4.98" stroke="#C7CFDD" stroke-width="2" stroke-linecap="round"
                        stroke-linejoin="round"></path>
                </svg>
              </template>
              <template #option="option">
                <div class="select__item d-center">{{ option.org_name || option.data.name.full_with_opf }} ({{ form.org_legal_adress.address || form.org_legal_adress }})</div>
              </template>
              <template #selected-option="option">
                <div class="selected d-center">{{ option.org_name || option.data.name.full_with_opf }} ({{ form.org_legal_adress.address || form.org_legal_adress }})</div>
              </template>
              <template #no-options="{ search, searching }">
                <div v-if="searching" class="select__noty">
                  По вашему запросу <em>{{ search }}</em> ничего не найдено.
                  <br>
                  Попробуйте еще раз.
                </div>
                <div v-else class="select__noty">
                  Для поиска организации в справочнике, введите наименование в формате: <span>Администрация ленинского района города Ставрополя</span> или ИНН <span>2634055412</span> организации и выберите значение из выпадающего списка.
                </div>
              </template>
            </v-select>
            <div
              v-if="form.org_name"
              class="select__long"
            >
              {{ form.org_name.org_name || form.org_name.data.name.full_with_opf }} ({{ form.org_legal_adress.address || form.org_legal_adress }})
            </div>
            <template #error>
              <div v-if="$v.form.org_name.$dirty && !$v.form.org_name.required">Обязательное поле</div>
            </template>
          </app-form-group>
          <app-form-group required label="Сокращенное название организации в соответствии с учредительными документами">
            <app-input
              v-model="form.org_name_short"
              id="org_name_short"
              placeholder="Введите значение"
              :readonly="readonlyCondition"
            />
          </app-form-group>
          <app-form-group required label="Выберите окончание для строки именуемый(ая, ое, ый) в дальнейшем...">
            <template #additional>
              <app-tooltip>
                <template #icon>
                  <icon-tooltip />
                </template>
                <template #content>
                  <span>Например: учреждение - ОЕ..., организация - АЯ..., фонд - ЫЙ</span>
                </template>
              </app-tooltip>
            </template>
            <app-cells position="start" :indent="false">
              <app-radio
                v-for="occ in ending_options"
                :key="occ.id"
                v-model="form.org_name_ending"
                :label="occ.name"
                :value="occ.name"
                name="org_name_ending"
                :readonly="readonlyCondition"
              />
            </app-cells>
            <template #error>
              <div v-if="$v.form.org_name_ending.$dirty && !$v.form.org_name_ending.required">Обязательное поле</div>
            </template>
          </app-form-group>
          <app-form-group required label="Должность подписывающего в родительном падеже с заглавной буквы" label-for="signer_position_rp">
            <app-input
              v-model="form.signer_position_rp"
              id="signer_position_rp"
              placeholder="Например: проректора по социальной работе"
              :error="$v.form.signer_position_rp.$error"
              :readonly="readonlyCondition"
            />
            <template #error>
              <div v-if="$v.form.signer_position_rp.$dirty && !$v.form.signer_position_rp.required">Обязательное поле</div>
            </template>
          </app-form-group>
          <app-form-group required label="ФИО подписывающего полностью в родительном падеже" label-for="signer_fullname_rp">
            <app-input
              v-model="form.signer_fullname_rp"
              id="signer_fullname_rp"
              placeholder="Например: Петрова Ивана Васильевича"
              :error="$v.form.signer_fullname_rp.$error"
              :readonly="readonlyCondition"
            />
            <template #error>
              <div v-if="$v.form.signer_fullname_rp.$dirty && !$v.form.signer_fullname_rp.required">Обязательное поле</div>
            </template>
          </app-form-group>
          <app-form-group required label="Нормативный актдокумент на основании которого действует подписывающий в родительном падеже" label-for="signer_doc_reason_rp">
            <app-input
              v-model="form.signer_doc_reason_rp"
              id="signer_doc_reason_rp"
              placeholder="Например: Устава или доверенности №... от..."
              :error="$v.form.signer_doc_reason_rp.$error"
              :readonly="readonlyCondition"
            />
            <template #error>
              <div v-if="$v.form.signer_doc_reason_rp.$dirty && !$v.form.signer_doc_reason_rp.required">Обязательное поле</div>
            </template>
          </app-form-group>
          <app-form-group required label="Должность подписывающего в именительном падеже с заглавной буквы" label-for="signer_position_ip">
            <app-input
              v-model="form.signer_position_ip"
              id="signer_position_ip"
              placeholder="Введите значение"
              :error="$v.form.signer_position_ip.$error"
              :readonly="readonlyCondition"
            />
            <template #error>
              <div v-if="$v.form.signer_position_ip.$dirty && !$v.form.signer_position_ip.required">Обязательное поле</div>
            </template>
          </app-form-group>
          <app-form-group required label="Инициалы, фамилия подписывающего в именительном падеже" label-for="signer_initials_ip">
            <app-input
              v-model="form.signer_initials_ip"
              id="signer_initials_ip"
              placeholder="Введите значение"
              :error="$v.form.signer_initials_ip.$error"
              :readonly="readonlyCondition"
            />
            <template #error>
              <div v-if="$v.form.signer_initials_ip.$dirty && !$v.form.signer_initials_ip.required">Обязательное поле</div>
            </template>
          </app-form-group>
          <app-form-group required label="Юридический адрес организации">
            <v-select
              v-model="form.org_legal_adress"
              :options="address_options"
              :clearable="false"
              :filterable="false"
              @search="onAddressSearch"
              label="address"
              placeholder="Введите значение"
              class="select"
              :class="{ 'select--error': $v.form.org_legal_adress.$error }"
              :readonly="readonlyCondition"
            >
              <template #open-indicator>
                <svg class="select__open-indicator" xmlns="http://www.w3.org/2000/svg" width="23" height="23"
                     fill="none">
                  <path clip-rule="evenodd" d="M10.06 17.25a7.19 7.19 0 100-14.38 7.19 7.19 0 000 14.38z"
                        stroke="#C7CFDD" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                  <path d="M20.12 20.12l-4.98-4.98" stroke="#C7CFDD" stroke-width="2" stroke-linecap="round"
                        stroke-linejoin="round"></path>
                </svg>
              </template>
              <template #option="option">
                <div class="select__item d-center">{{ option.address }}</div>
              </template>
              <template #selected-option="option">
                <div class="selected d-center">{{ option.address }}</div>
              </template>
              <template #no-options="{ search, searching }">
                <div v-if="searching" class="select__noty">
                  По вашему запросу <em>{{ search }}</em> ничего не найдено.
                  <br>
                  Попробуйте еще раз.
                </div>
                <div v-else class="select__noty">
                  Для поиска адреса в справочнике, введите адрес в формате: <span>Ставрополь, ул. Пушкина, д 65в, кв. 1</span> и выберите значение из выпадающего списка.
                </div>
              </template>
            </v-select>
            <template #error>
              <div v-if="$v.form.org_legal_adress.$dirty && !$v.form.org_legal_adress.required">Обязательное поле</div>
            </template>
          </app-form-group>
          <app-form-group label="Фактический адрес организации" required>
            <v-select
              v-model="form.org_fact_adress"
              :options="address_options"
              :clearable="false"
              :filterable="false"
              @search="onAddressSearch"
              label="address"
              placeholder="Введите значение"
              class="select"
              :class="{ 'select--error': $v.form.org_fact_adress.$error }"
              :readonly="readonlyCondition"
            >
              <template #open-indicator>
                <svg class="select__open-indicator" xmlns="http://www.w3.org/2000/svg" width="23" height="23"
                     fill="none">
                  <path clip-rule="evenodd" d="M10.06 17.25a7.19 7.19 0 100-14.38 7.19 7.19 0 000 14.38z"
                        stroke="#C7CFDD" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                  <path d="M20.12 20.12l-4.98-4.98" stroke="#C7CFDD" stroke-width="2" stroke-linecap="round"
                        stroke-linejoin="round"></path>
                </svg>
              </template>
              <template #option="option">
                <div class="select__item d-center">{{ option.address }}</div>
              </template>
              <template #selected-option="option">
                <div class="selected d-center">{{ option.address }}</div>
              </template>
              <template #no-options="{ search, searching }">
                <div v-if="searching" class="select__noty">
                  По вашему запросу <em>{{ search }}</em> ничего не найдено.
                  <br>
                  Попробуйте еще раз.
                </div>
                <div v-else class="select__noty">
                  Для поиска адреса в справочнике, введите адрес в формате: <span>Ставрополь, ул. Пушкина, д 65в, кв. 1</span> и выберите значение из выпадающего списка.
                </div>
              </template>
            </v-select>
            <template #error>
              <div v-if="$v.form.org_fact_adress.$dirty && !$v.form.org_fact_adress.required">Обязательное поле</div>
            </template>
          </app-form-group>
          <app-form-group required label="ИНН">
            <app-input
              v-model="form.inn"
              placeholder="Введите значение"
              readonly
            />
          </app-form-group>
          <app-form-group label="КПП">
            <app-input
              v-model="form.kpp"
              placeholder="Введите значение"
              readonly
            />
          </app-form-group>
          <app-form-group required label="ОГРН">
            <app-input
              v-model="form.ogrn"
              placeholder="Введите значение"
              readonly
            />
          </app-form-group>
          <app-form-group required label="Р/С" label-for="rs">
            <app-input
              v-model="form.rs"
              id="rs"
              placeholder="Введите значение"
              :error="$v.form.rs.$error"
              :readonly="readonlyCondition"
            />
            <template #error>
              <div v-if="$v.form.rs.$dirty && !$v.form.rs.required">Обязательное поле</div>
            </template>
          </app-form-group>
          <app-form-group v-if="form.info_of_bank" label="БИК" required>
            <v-select
              v-model="form.info_of_bank"
              :options="bank_options"
              @search="onBankSearch"
              @input="onSetBank"
              :clearable="false"
              :filterable="false"
              label="unrestricted_value"
              placeholder="Введите значение"
              class="select"
              :class="{ 'select--error': $v.form.info_of_bank.$error }"
              :readonly="readonlyCondition"
            >
              <template #open-indicator>
                <svg class="select__open-indicator" xmlns="http://www.w3.org/2000/svg" width="23" height="23"
                     fill="none">
                  <path clip-rule="evenodd" d="M10.06 17.25a7.19 7.19 0 100-14.38 7.19 7.19 0 000 14.38z"
                        stroke="#C7CFDD" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                  <path d="M20.12 20.12l-4.98-4.98" stroke="#C7CFDD" stroke-width="2" stroke-linecap="round"
                        stroke-linejoin="round"></path>
                </svg>
              </template>
              <template #option="option">
                <div class="select__item d-center">{{ option.data.bic }} ({{option.unrestricted_value}})</div>
              </template>
              <template #selected-option="option">
                <div class="selected d-center">{{ option.data.bic }} ({{option.unrestricted_value}})</div>
              </template>
              <template #no-options>Ничего не найдено</template>
            </v-select>
            <template #error>
              <div v-if="$v.form.info_of_bank.$dirty && !$v.form.info_of_bank.required">Обязательное поле</div>
            </template>
          </app-form-group>
          <app-form-group required label="Наименование банка и город">
            <app-input
              v-model="form.bank"
              placeholder="Введите значение"
              readonly
            />
          </app-form-group>
          <app-form-group required label="Тип счета">
            <app-cells position="start" :indent="false">
              <app-radio
                v-for="occ in account_type_options"
                :key="occ.id"
                v-model="form.account_type"
                :label="occ.name"
                :value="occ.name"
                name="account_type"
                :readonly="readonlyCondition"
              />
            </app-cells>
            <template #error>
              <div v-if="$v.form.account_type.$dirty && !$v.form.account_type.required">Обязательное поле</div>
            </template>
          </app-form-group>
          <app-form-group required label="Номер счета" label-for="account_number">
            <app-input
              v-model="form.account_number"
              id="account_number"
              placeholder="Введите значение"
              :error="$v.form.account_number.$error"
              :readonly="readonlyCondition"
            />
            <template #error>
              <div v-if="$v.form.account_number.$dirty && !$v.form.account_number.required">Обязательное поле</div>
            </template>
          </app-form-group>
        </template>
        <template #item-2>
          <app-form-group required label="ФИО контактного лица" label-for="contact_name">
            <app-input
              v-model="form.contact_name"
              id="contact_name"
              placeholder="Введите значение"
              :error="$v.form.contact_name.$error"
              :readonly="readonlyCondition"
            />
            <template #error>
              <div v-if="$v.form.contact_name.$dirty && !$v.form.contact_name.required">Обязательное поле</div>
            </template>
          </app-form-group>
          <app-form-group required label="Адрес электронной почты для направления документов и счета на оплату" label-for="email_for_sending">
            <app-input
              v-model="form.email_for_sending"
              id="email_for_sending"
              type="email"
              placeholder="Введите значение"
              :error="$v.form.email_for_sending.$error"
              :readonly="readonlyCondition"
            />
            <template #error>
              <div v-if="$v.form.email_for_sending.$dirty && !$v.form.email_for_sending.required">Обязательное поле</div>
              <div v-if="$v.form.email_for_sending.$dirty && !$v.form.email_for_sending.email">Некорректный формат электронной почты</div>
            </template>
          </app-form-group>
          <app-form-group label="Контактный телефон для оперативной связи" required>
            <app-phone
              v-model="form.contact_phone"
              :error="$v.form.contact_phone.$dirty && (!$v.form.contact_phone.required || (form.contact_phone === 0))"
              @change.native="$v.form.contact_phone.$touch()"
              @paste.native.prevent
              :readonly="readonlyCondition"
            />
            <template #error>
              <div v-if="$v.form.contact_phone.$dirty && !$v.form.contact_phone.required">Обязательное поле</div>
              <div v-if="$v.form.contact_phone.$dirty && (form.contact_phone === 0) && $v.form.contact_phone.required">
                Неправильный формат номера
              </div>
            </template>
          </app-form-group>
          <app-form-group label="Участники делегации, за которых вносится оплата по этому договору" required>
            <v-select
              v-model="form.participants"
              :reduce="item => item.pk"
              :options="participants_options"
              :filterable="true"
              label="last_name"
              placeholder="Выберите участников"
              class="select select--multiple"
              :class="{ 'select--error': $v.form.participants.$error }"
              multiple
              :readonly="readonlyCondition"
            >
              <template #open-indicator>
                <svg width="18" height="18" fill="none" class="open-indicator" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="9" cy="9" r="9" fill="#2b93e7"></circle>
                  <path
                    d="M8.6 11.82L5.16 8.05a.66.66 0 010-.87.53.53 0 01.8 0L9 10.52l3.04-3.34a.53.53 0 01.8 0c.21.24.21.63 0 .87L9.4 11.82A.53.53 0 019 12a.53.53 0 01-.4-.18z"
                    fill="#fff"></path>
                </svg>
              </template>
              <template #option="option">
                <div class="select__item d-center">{{ option.last_name }} {{ option.first_name }} {{ option.patronymic ? option.patronymic : '' }}</div>
              </template>
              <template #selected-option="option">
                <div class="selected d-center">{{ option.last_name }} {{ option.first_name }} {{ option.patronymic ? option.patronymic : '' }}</div>
              </template>
              <template #no-options>Ничего не найдено</template>
            </v-select>
            <template #error>
              <div v-if="$v.form.participants.$dirty && !$v.form.participants.required">Обязательное поле</div>
            </template>
          </app-form-group>
          <app-form-group required label="Количество участников делегации Заказчика">
            <app-input
              :value="form.participants.length"
              placeholder="Введите значение"
              readonly
            />
          </app-form-group>
          <app-form-group
            required
            :label="app_name === 'oovo' ? 'В том числе количество участников в пределах квоты (9 500 руб.чел.)' : 'В том числе количество участников в пределах квоты (6 000 руб.чел.)'"
            label-for="count_qouta"
          >
            <app-input
              v-model="form.count_qouta"
              id="count_qouta"
              placeholder="Введите значение"
              type="number"
              :error="$v.form.count_qouta.$error"
              :readonly="readonlyCondition"
            />
            <template #error>
              <div v-if="$v.form.count_qouta.$dirty && !$v.form.count_qouta.required">Обязательное поле</div>
            </template>
          </app-form-group>
          <app-form-group
            required
            :label="app_name === 'oovo' ? 'В том числе количество участников сверх квоты (21 000 руб.чел.)' : 'В том числе количество участников сверх квоты (15 000 руб.чел.)'"
            label-for="count_qouta_out"
          >
            <app-input
              v-model="form.count_qouta_out"
              id="count_qouta_out"
              placeholder="Введите значение"
              type="number"
              :error="$v.form.count_qouta_out.$error"
              :readonly="readonlyCondition"
            />
            <template #error>
              <div v-if="$v.form.count_qouta_out.$dirty && !$v.form.count_qouta_out.required">Обязательное поле</div>
            </template>
          </app-form-group>
          <app-form-group label="Порядок оплаты" required>
            <v-select
              v-model="form.payment_procedure"
              :reduce="item => item.id"
              :options="payment_procedure_options"
              :clearable="false"
              label="name"
              placeholder="Выберите значение"
              class="select"
              :class="{ 'select--error': $v.form.payment_procedure.$error }"
              :readonly="readonlyCondition"
            >
              <template #open-indicator>
                <svg width="18" height="18" fill="none" class="open-indicator" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="9" cy="9" r="9" fill="#2b93e7"></circle>
                  <path
                    d="M8.6 11.82L5.16 8.05a.66.66 0 010-.87.53.53 0 01.8 0L9 10.52l3.04-3.34a.53.53 0 01.8 0c.21.24.21.63 0 .87L9.4 11.82A.53.53 0 019 12a.53.53 0 01-.4-.18z"
                    fill="#fff"></path>
                </svg>
              </template>
              <template #option="option">
                <div class="select__item d-center">{{ option.name }}</div>
              </template>
              <template #selected-option="option">
                <div class="selected d-center">{{ option.name }}</div>
              </template>
              <template #no-options>Ничего не найдено</template>
            </v-select>
            <template #error>
              <div v-if="$v.form.payment_procedure.$dirty && !$v.form.payment_procedure.required">Обязательное поле</div>
            </template>
          </app-form-group>
          <app-form-group label="Дополнительные сведения в преамбуле" label-for="details_preamble">
            <app-input
              v-model="form.details_preamble"
              id="details_preamble"
              placeholder="Введите значение"
            />
          </app-form-group>
          <app-form-group label="Загрузите гарантийное письмо об оплате">
            <app-uploader
              v-model="form.guarantee_letter"
              :readonly="readonlyCondition"
            />
          </app-form-group>
        </template>
      </app-grid>
      <div v-if="userIsAdmin" class="color-panel color-panel--indent">
        <app-cells position="start">
          <h2 class="title">Управление</h2>
        </app-cells>
        <app-grid items="3" col="3">
          <template #item-1>
            <app-form-group label="Служебный комментарий">
              <app-textarea
                v-model="form.comment_staff"
                id="comment_staff"
                placeholder="Введите комментарий"
              />
            </app-form-group>
          </template>
          <template #item-2>
            <app-form-group label="Комментарий отказа">
              <app-textarea
                v-model="form.comment_reject"
                id="comment_reject"
                placeholder="Введите комментарий"
              />
            </app-form-group>
          </template>
          <template #item-3>
            <app-form-group label="Статус">
              <v-select
                v-model="form.status"
                :reduce="item => item.id"
                :options="status_options"
                :filterable="false"
                :clearable="false"
                :searchable="false"
                label="name"
                placeholder="Выберите значение"
                class="select"
              >
                <template #open-indicator>
                  <svg width="18" height="18" fill="none" class="open-indicator" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="9" cy="9" r="9" fill="#2b93e7"></circle>
                    <path d="M8.6 11.82L5.16 8.05a.66.66 0 010-.87.53.53 0 01.8 0L9 10.52l3.04-3.34a.53.53 0 01.8 0c.21.24.21.63 0 .87L9.4 11.82A.53.53 0 019 12a.53.53 0 01-.4-.18z" fill="#fff"></path>
                  </svg>
                </template>
                <template #option="option">
                  <div class="select__item d-center">{{ option.name }}</div>
                </template>
                <template #selected-option="option">
                  <div class="selected d-center">{{ option.name }}</div>
                </template>
                <template #no-options>Ничего не найдено</template>
              </v-select>
            </app-form-group>
            <app-cells>
              <app-button ref="submit" :disabled="$v.form.$error">Сохранить изменения</app-button>
            </app-cells>
          </template>
        </app-grid>
      </div>
      <app-cells v-if="!userIsAdmin && (form.status === 1 || form.status === 3)">
        <app-button
          @click="onSendFormWithChangeStatus(form.status)"
          type="button"
          ref="submit"
          theme="transparent"
          :disabled="$v.form.$error || (form.contact_phone === 0)"
        >
          Сохранить
        </app-button>
        <app-button
          @click="onSendFormWithChangeStatus(2)"
          type="button"
          ref="submitReview"
          :disabled="$v.form.$error || (form.contact_phone === 0)"
        >
          Отправить на проверку
        </app-button>
      </app-cells>
    </form>
    <modal
      name="delete-modal"
      :width="750"
      :height="'auto'"
      :scrollable="true"
      :adaptive="true"
    >
      <div class="modal">
        <div class="modal__title modal__title--center-black">Подтверждение действия</div>
        <p class="modal__text">
          Вы собираетесь удалить договор - ID: {{ form.id }}.
          Это действие невозможно будет отменить и данные будут удалены безвозвратно. Вы уверены, что хотите это сделать?
        </p>
        <app-cells position="center">
          <app-button size="small" @click="onDeleteNumber" ref="submitDelete">Да</app-button>
          <app-button size="small" @click="onHideModal">Нет</app-button>
        </app-cells>
      </div>
    </modal>
  </div>
</template>

<script>
import { dateFormatting } from '@/helpers'
import {
  getAddress,
  getFullOrg,
  getBank,
  getMembersList,
  getPaymentMethod,
  putContractLegal,
  getContractLegal,
  getStatus,
  deleteContractLegal,
  getContractLegalExport
} from '@/http'
import { debounce } from 'lodash'
import { required, email } from 'vuelidate/lib/validators'
import IconTooltip from '@/components/icons/IconTooltip'

const underscorePresent = value => {
  if (!value) return true
  else return !value.includes('_')
}

export default {
  name: 'ContractsLegalEdit',
  data() {
    return {
      form: {
        participants: [],
        org_name: {},
        bik: {},
      },
      org_options: [],
      address_options: [],
      bank_options: [],
      ending_options: [
        {
          id: 1,
          name: 'ый'
        },
        {
          id: 2,
          name: 'ое'
        },
        {
          id: 3,
          name: 'ая'
        },
      ],
      account_type_options: [
        {
          id: 1,
          name: 'л/c'
        },
        {
          id: 2,
          name: 'к/с'
        },
      ],
      participants_options: [],
      payment_procedure_options: [],
      status_options: [],
      status_to_set: null,
      download_app: {
        word: false,
        word_seal: false,
        pdf: false,
        pdf_seal: false,
      },
      app_name: process.env.VUE_APP_FESTIVAL_NAME,
    }
  },
  components: {
    IconTooltip,
  },
  validations: {
    form: {
      date: { underscorePresent },
      org_name: { required },
      org_name_ending: { required },
      signer_position_rp: { required },
      signer_fullname_rp: { required },
      signer_doc_reason_rp: { required },
      signer_position_ip: { required },
      signer_initials_ip: { required },
      org_fact_adress: { required },
      org_legal_adress: { required },
      rs: { required },
      account_type: { required },
      account_number: { required },
      info_of_bank: { required },
      contact_name: { required },
      contact_phone: { required },
      email_for_sending: { required, email },
      participants: { required },
      count_qouta: { required },
      count_qouta_out: { required },
      payment_procedure: { required },
    }
  },
  computed: {
    userIsHead() {
      return this.$store.state.user_role === 'head'
    },
    userIsAdmin() {
      return this.$store.state.user_role === 'admin'
    },
    readonlyCondition() {
      return !this.userIsAdmin && (this.form.status === 2 || this.form.status === 4)
    },
    statusObj() {
      if (!this.status_options.length || !this.form.status) return
      return this.status_options.find(item => item.id === this.form.status)
    }
  },
  created() {
    getMembersList({page_size: 200})
      .then(response => {
        this.participants_options = response.data.results
      })
    getPaymentMethod()
      .then(response => {
        this.payment_procedure_options = response.data
      })
    getContractLegal(this.$route.params.id)
      .then(response => {
        this.form = response.data
        if (this.form.date) this.form.date = dateFormatting(response.data.date, 'iso-to-normal-small')
        // getFullOrg(this.form.org_name.org_name_short)
        //   .then(response => {
        //     this.form.org_name = response.data[0]
        //   })
        // getBank(this.form.bik)
        //   .then(response => {
        //     this.form.bik = response.data[0]
        //   })
      })
      .catch(() => {
        this.$router.push({ name: 'contracts-list' })
      })
    getStatus()
      .then(response => {
        this.status_options = response.data
      })
  },
  methods: {
    onDownloadApplication(pdf, extension, download, seal) {
      this.download_app[download] = true
      getContractLegalExport(this.$route.params.id, pdf, seal)
        .then(response => {
          const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', `Договор с юридическим лицом_${this.$route.params.id}.${extension}`)
          document.body.appendChild(link)
          link.click()
          URL.revokeObjectURL(link.href)
        })
        .finally(() => {
          this.download_app[download] = false
        })
    },
    onShowModal() {
      this.$modal.show('delete-modal')
    },
    onHideModal() {
      this.$modal.hide('delete-modal')
    },
    onDeleteNumber() {
      this.$refs.submitDelete.preload = true
      deleteContractLegal(this.$route.params.id)
        .finally(() => {
          this.$refs.submitDelete.preload = false
        })
        .then(() => {
          this.$notify({
            type: 'success',
            title: 'Успех!',
            text: 'Договор удален'
          })
          this.$router.push({ name: 'contracts-list' })
        })
    },
    onSendFormWithChangeStatus(status) {
      this.status_to_set = status
      this.onCheckForm()
    },
    onCheckForm() {
      this.$v.form.$touch()
      if (this.$v.form.$invalid) {
        this.$notify({
          type: 'warn',
          title: 'Внимание!',
          text: 'Проверьте правильность заполнения полей формы.'
        })
      } else {
        this.sendForm()
      }
    },
    sendForm() {
      this.$refs.submit.preload = true
      if (this.$refs.submitReview) this.$refs.submitReview.preload = true
      putContractLegal(this.$route.params.id, this.normalizeForm())
        .finally(() => {
          this.$refs.submit.preload = false
          if (this.$refs.submitReview) this.$refs.submitReview.preload = false
        })
        .then(() => {
          this.$notify({
            type: 'success',
            title: 'Успех!',
            text: 'Договор изменен'
          })
          this.$router.push({ name: 'contracts-list' })
        })
    },
    normalizeForm() {
      const form = {...this.form}
      if (form.date) form.date = dateFormatting(form.date, 'normal-to-iso-small')
      else form.date = null
      if (form.org_name.data) form.org_name = form.org_name.data.name.full_with_opf
      else form.org_name = form.org_name.org_name
      if (typeof form.org_fact_adress === 'object') form.org_fact_adress = form.org_fact_adress.address
      if (typeof form.org_legal_adress === 'object') form.org_legal_adress = form.org_legal_adress.address
      form.bik = form.info_of_bank.data.bic
      if (form.guarantee_letter && form.guarantee_letter.id) form.guarantee_letter = form.guarantee_letter.id
      else delete form.guarantee_letter
      if (this.status_to_set) form.status = this.status_to_set

      return form
    },
    onSetOrgName() {
      this.form.org_name_short = this.form.org_name.data.name.short_with_opf
      this.form.org_legal_adress = this.form.org_name.data.address.unrestricted_value
      this.form.inn = this.form.org_name.data.inn
      this.form.kpp = this.form.org_name.data.kpp
      this.form.ogrn = this.form.org_name.data.ogrn
    },
    onSetBank() {
      this.form.bank = `${this.form.info_of_bank.unrestricted_value}, ${this.form.info_of_bank.data.payment_city}`
    },
    onOrgSearch(search, loading) {
      loading(true)
      this.searchOrg(loading, search, this)
    },
    searchOrg: debounce((loading, search, vm) => {
      getFullOrg(search).then(response => {
        vm.org_options = response.data
        loading(false)
      })
    }, 350),
    onAddressSearch(search, loading) {
      loading(true)
      this.searchAddress(loading, search, this)
    },
    searchAddress: debounce((loading, search, vm) => {
      getAddress(search).then(response => {
        vm.address_options = response.data
        loading(false)
      })
    }, 350),
    onBankSearch(search, loading) {
      loading(true)
      this.searchBank(loading, search, this)
    },
    searchBank: debounce((loading, search, vm) => {
      getBank(search).then(response => {
        vm.bank_options = response.data
        loading(false)
      })
    }, 350),
  }
}
</script>
